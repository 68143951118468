import { Box, SystemProps } from "@storyofams/react-ui";
type SpacerProps = {
  content: {
    mobile_pixels: number;
    desktop_pixels?: number;
  };
} & SystemProps;
export const Spacer = ({ content }: SpacerProps) => (
  <Box
    width={"100%"}
    height={[content.mobile_pixels + "px", content.desktop_pixels + "px"]}
  />
);
